import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Zoom from './zoom-popup.png'
import { useLocation } from 'react-router-dom';


const Container = styled.div`
  width: 100vw;
  width: 100%; // scroll into account
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  animation: fadeIn 2s;

  &.fadeOut {
    animation: fadeOut 0.5s;
    opacity: 0;
  }
  &.out {
    display: none;
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
`


const Popup = styled.div`
  max-width: 300px;
  max-height: 300px;
  width: 100%;
  height: 100%;
  background: url(${Zoom});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
`

function ZoomPopup() {
  const [stop, set_stop] = useState(false);
  const [fadeout, set_fadeout] = useState(false);

  return (
    <Container
      onClick={() => {
        setTimeout(() => { set_stop(true) }, 600)
        set_fadeout(true);
      }}
      className={
        `${stop ? 'out' : ''} ${fadeout ? 'fadeOut' : ''}`
      }
    >
      <Popup />
    </Container>
  );
}

export default ZoomPopup;
