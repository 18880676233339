import React, { useState, useEffect } from 'react';
import styled from "@emotion/styled";
import { Link, useLocation } from "react-router-dom";

const Container = styled.div`
    /* width: 100vw; */
    overflow: hidden;

    display: flex;
    flex-direction: column;
    gap: 6px;

    &.chapter{
        visibility: hidden;
    }
`

const Title = styled.div`
  //  width: calc( 100vw - 16px );
    /* width: 100%; */
    overflow: hidden;

    user-select: none;
    min-height: 40px;
    font-size: 15px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    text-align: center;
    span {
        padding: 5px;
    }
    a {
        z-index: 1;
    }
`

const Menu = styled.div`
    display: flex;
    justify-content: center;
    overflow: hidden;
    font-size: 15px;
    /* margin-top: 16px;
    margin-bottom: 16px; */
    width: 100%;

    .center {
        display: flex;
        gap: 32px;
        width: 100%;
        max-width: 1200px;
        justify-content: center;
    }

    @media only screen and (min-width: 1000px)  {
        position: absolute;
        top: 6px;
        margin-top: 6px;
        width: calc( 100% - 16px );
        .center {
           justify-content: flex-start;
        }
    }
`

const SLink = styled(Link)`
    text-decoration: none;
    color: black;
    z-index: 1;
    &.active {
        text-decoration: underline;
    }
`


function Header(props) {
    const { pathname } = useLocation();

    return (
        <Container className={`HeaderContainer ${pathname.includes('chapter') ? 'chapter' : ''}`}>
            <>
                <Title
                    className={`Header Title`}
                    id={`Header`}
                >
                    <SLink
                        to={`/about`}
                        className={pathname === `/about` ? 'active' : ''}
                    >
                        Roma Jam Session art Kollektiv
                    </SLink>
                </Title>
                <Menu className={`Header Menu`}>
                    <div className='center'>
                        <SLink
                            to={`/`}
                            className={pathname === `/` ? 'active' : ''}
                        >
                            Morphing
                        </SLink>
                        {/* <SLink>
                        Collaborations
                        </SLink> */}
                    </div>
                </Menu>
            </>
        </Container>
    );
  }

  export default Header;
