export const data = {
    "nodes": [
        {
            "name": "Gesellschaft für bedrohte Völker Schweiz, Bern",
            "id": "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac",
            "target": "cat-collaborators",
            "width": 360,
            "body": "Society for Threatened Peoples, Switzerland (STP), an international human rights organisation that supports minorities and indigenous peoples. STP documents impacts on indigenous people, informs and sensitises the public, and represents the interests of victims against authorities and decision makers. STP supports local efforts to improve the human rights situation for minorities and indigenous peoples. STP has advisory status both at the Economic and Social Council ECOSOC of the UN and at the Council of Europe.",
            "urls": [
                "https://www.gfbv.ch"
            ],
            relations: [
                "2d491ede-b5ad-455b-bb8e-67d4f8fd6bbe",
                "5625f2b2-3d6e-4ad0-8a68-614c652c3241",
                "fbfdc394-1e11-4a22-97b8-607ae36894a3",
                "83f46038-544d-4130-b746-1c1ebaa01198",
                "d50649e2-6ca7-49c3-962e-e44a6e9029f9",
                "657bf782-6e86-40d1-b533-74899a9ffb51",
                "5515fc9f-2fcc-44d0-8f11-bdc2c89a8ed0",
                "1bae4505-239f-4d79-99f6-35a5a218d892",
                "b113be42-cc6a-47b6-8714-d3eba3fcfe5a",
                "b6c1a67c-2a74-4da0-9407-fa84a1878601",
                "d2fa5441-653b-4ef5-bdb0-5bc7ddd9515f",
                "223984ec-3350-44fa-a19d-2c85644c5742",
                "8ba3e542-22a1-4b81-8064-b3eafcf84da4",
                "52d5d35b-a0ec-48ea-8400-eabb37cb99e4",
                "73ffe81c-3169-4761-8638-b980b6420ae6",
                "726ba14c-3a9e-4b8c-96c3-0cfbb448b683",
                "a8dac73a-e544-4e03-98b0-bb22d12ab9a9",
                "18c3a1a1-465f-4497-980f-dd1a5005c786",
                "dda75e1e-70c8-4a6c-82d0-7b78692269d6",
            ]
        },
        {
            "name": "2nd Roma Biennale, Berlin",
            "id": "2fda9539-a295-4fe0-b8e4-9e2c2bbf9656",
            "target": "cat-collaborators",
            "width": 201,
            "body": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sit amet nulla leo. Sed suscipit dui in congue placerat. Nam leo orci, pulvinar a hendrerit laoreet, fringilla quis libero. Nunc faucibus efficitur lacinia. Duis sit amet augue est. Quisque rutrum velit lacinia sem sagittis luctus.",
            "urls": [
                "https://roma-biennale.com/",
                "https://www.zeit.de/kultur/2018-04/roma-biennale-maxim-gorki-theater-berlin/seite-2"
            ]
        },
        {
            "name": "Helmhaus Zürich",
            "id": "19e68797-acb8-42a4-92d6-fa2118735eee",
            "target": "cat-collaborators",
            "width": 131,
            "body": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sit amet nulla leo. Sed suscipit dui in congue placerat. Nam leo orci, pulvinar a hendrerit laoreet, fringilla quis libero. Nunc faucibus efficitur lacinia. Duis sit amet augue est. Quisque rutrum velit lacinia sem sagittis luctus.",
            "urls": [
                "https://helmhaus.org/de/"
            ]
        },
        {
            "name": "ERIAC",
            "id": "3e92649c-c213-4d1f-bc33-ae68d0e5dbcc",
            "target": "cat-collaborators",
            "width": 51,
            "body": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sit amet nulla leo. Sed suscipit dui in congue placerat. Nam leo orci, pulvinar a hendrerit laoreet, fringilla quis libero. Nunc faucibus efficitur lacinia. Duis sit amet augue est. Quisque rutrum velit lacinia sem sagittis luctus.",
            "urls": [
                "https://eriac.org/"
            ],
            relations: [
                "83f46038-544d-4130-b746-1c1ebaa01198",
                "60ca306c-b050-4eed-bd3b-0a6f59b0e8e1",
                "0faa7d73-61e9-41ec-a69f-ab8cdeed37f1",
                "0611b2c0-cda0-4f55-9277-fd778a0d564f",
            ]
        },
        {
            "name": "Ignoranz ist Gift: Respekt und Anerkennung für Roma",
            "id": "32b3e1e8-ad97-4b7c-8195-d1acb685e8ba",
            "target": "cat-articles",
            "width": 402
        },
        {
            "name": "Romaday 2018 First Roma-Biennale “Come Out Now”",
            "id": "00264546-0b8a-435d-a999-dfa6e9e503cc",
            "target": "cat-articles",
            "width": 406,
            "urls": [
                "https://www.stiftung-denkmal.de/aktuelles/veranstaltungsbericht/romaday-2018-und-erste-roma-biennale-come-out-now/"
            ]
        },
        {
            "name": "Roma Holocaust Memorial Day, 1st Aug 2017",
            "id": "5625f2b2-3d6e-4ad0-8a68-614c652c3241",
            "target": "cat-articles",
            "width": 339,
            "urls": [
                "https://www.gfbv.ch/de/medien/medienmitteilungen/roma-holocaust-memorial-day-gedenken-an-die-opfer/"
            ],
            relations: [
                "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac",
            ]
        },
        {
            "name": "Biennale di Venezia 2019",
            "id": "2c5f75ec-458d-4718-b4fb-736858c6b48d",
            "target": "cat-performances",
            "width": 190,
            "body": "We performed at during the «Roma Tangible Heritage Network»-conference, organized by ERIAC"
        },
        {
            "name": "Performance-Excerpt",
            "id": "90793963-25e7-40c2-ac52-9c7ca72fe23d",
            "target": "cat-performances",
            "width": 168
        },
        {
            "name": "Interview",
            "id": "145c025d-2e4c-49e4-a08f-ffea5fcc98b9",
            "target": "cat-talks",
            "width": 71,
            "body": "recorded during…",
            "urls": [
                "https://youtu.be/skS3MMvEMOk"
            ]
        },
        {
            "name": "TV-Episode",
            "id": "79248642-7077-4440-b3c2-e83f015f2bc5",
            "target": "cat-talks",
            "width": 90,
            "body": "recorded during…",
            "urls": [
                "https://www.youtube.com/watch?v=s3WG2jC3RXg"
            ]
        },
        {
            "name": "«Wir fordern Respekt und Anerkennung jetzt!», GfbV Schweiz",
            "id": "fbfdc394-1e11-4a22-97b8-607ae36894a3",
            "target": "cat-talks",
            "width": 464,
            relations: [
                "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac",
            ]
        },
        {
            "name": "The Displaced and Privilege: Live Art in the age of hostility",
            "id": "97eb1bf0-8ed1-41a1-8e32-d5e5e34335bd",
            "target": "cat-theory",
            "width": 436,
            "body": "Elena Marchevska",
            "urls": [
                "https://www.academia.edu/34897410/The_Displaced_and_Privilege_Live_Art_in_the_age_of_hostility?email_work_card=title"
            ]
        },
        {
            "name": "Rewriting the Protocols: Naming, Renaming, and Profiling",
            "id": "adf89bad-959b-4728-8ebc-380ba16dbdbd",
            "target": "cat-theory",
            "width": 431,
            "body": "Suzana Milevska",
            "urls": [
                "https://www.romarchive.eu/de/visual-arts/subsection-rewriting-protocols/rewriting-protocols-naming-renaming-and-profiling"
            ],
            relations: [
                "81f38822-7bbe-447a-87c7-cd9f2aead47b"
            ]
        },
        {
            "name": "Resistance & Resilience",
            "id": "83f46038-544d-4130-b746-1c1ebaa01198",
            "target": "cat-talks",
            "width": 182,
            "body": "Artist talk “Resistance & Resilience” with Roland Korponovics, Delaine Le Bas, Sujatro Ghosh, Kálmán Várady, Ludovic Versace, RJSaK during the Roma Biennale 2021",
            "urls": [
                "https://roma-biennale.com/videos/artist-talk-phase-2-resistance-resilience/"
            ],
            relations: [
                "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac",
                "3e92649c-c213-4d1f-bc33-ae68d0e5dbcc",
            ]
        },
        {
            "name": "Kupper Modern",
            "id": "0b97a7c8-5a2f-477b-854c-f112a61c5505",
            "target": "cat-collaborators",
            "width": 134,
            "body": "Gallery of Contemporary Art and Design Studio",
            "urls": [
                "https://www.kupper-modern.com"
            ],
            relations: [
                "b1915240-d656-4022-bd97-23f729c15fc5"
            ]
        },
        {
            "name": "Mihaela Zatreanu",
            "id": "a6d1d5dd-2183-411f-9707-921dd68ccd02",
            "target": "cat-collaborators",
            "width": 134,
            "body": "ERIAC Romani Translater, Researcher, Trainer",
            "urls": [
                "https://eriac.org/members/mihaela-zatreanu/"
            ]
        },
        {
            "name": "Katja Schurter – Textwurf",
            "id": "69eaea20-61ce-4525-b7e7-f8a655f6078d",
            "target": "cat-collaborators",
            "width": 194,
            "body": "Proofreading, editing, translation",
            "urls": [
                "https://www.textwurf.ch"
            ]
        },
        {
            "name": "Timea Junghaus",
            "id": "60ca306c-b050-4eed-bd3b-0a6f59b0e8e1",
            "target": "cat-collaborators",
            "width": 127,
            "body": "Director of ERIAC",
            "urls": [
                "https://eriac.org/members/timeaj/"
            ],
            relations: [
                "3e92649c-c213-4d1f-bc33-ae68d0e5dbcc"
            ]
        },
        {
            "name": "Gilda-Nancy Horvath",
            "id": "0faa7d73-61e9-41ec-a69f-ab8cdeed37f1",
            "target": "cat-collaborators",
            "width": 160,
            "body": "Roma Activist",
            "urls": [
                "https://www.romarchive.eu/de/collection/interview-gilda-nancy-horvath-the-front-page-of-the-new-york-times-2/"
            ],
            relations: [
                "3e92649c-c213-4d1f-bc33-ae68d0e5dbcc"
            ]
        },
        {
            "name": "Gilda-Nancy Horvath",
            "target": "cat-collaborators",
            "width": 160,
            "id": "83263e81-3a3e-4b61-80d3-43dcb91983b3",
            "body": "ERIAC Board Member",
            "urls": [
                "www.eriac.org"
            ]
        },
        {
            "name": "Roma Trial",
            "id": "d0e886f9-2abd-416d-b3e7-cee5d7871f3a",
            "target": "cat-collaborators",
            "width": 81,
            "body": "1. und 2. Roma Biennale Berlin",
            "urls": [
                "romatrial.org"
            ],
            relations: [
                "58c2a9b3-0473-4692-bca1-72d70ad821d6"
            ]
        },
        {
            "name": "MoCA Museum of Modern Art Skopje",
            "id": "be3b2479-bbcc-48eb-af22-cbb700697fcc",
            "target": "cat-collaborators",
            "width": 286,
            "urls": [
                "https://msu.mk"
            ],
            relations: [
                "879d8ce6-48a4-4bf2-bdfe-24311728ee4e",
                "be7a3d15-c879-42e0-9565-73935546b040",
            ]
        },
        {
            "name": "Nehrun Aliev",
            "id": "c237723c-1691-4084-9d14-8c7ab8bb3f8b",
            "target": "cat-collaborators",
            "width": 99,
            "body": "Composer, Musician",
            "urls": [
                "http://aliev.ch"
            ]
        },
        {
            "name": "Galerie Kai Dikhas",
            "id": "20670e69-3dbc-44e5-b13e-a3e57a0b8cfc",
            "target": "cat-collaborators",
            "width": 141,
            "urls": [
                "https://kaidikhas.com"
            ],
            relations: [
                "85a460b9-e317-4868-9c5b-23198c81a4be"
            ]
        },
        {
            "name": "Moritz Pankok",
            "id": "85a460b9-e317-4868-9c5b-23198c81a4be",
            "target": "cat-collaborators",
            "width": 111,
            relations: [
                "20670e69-3dbc-44e5-b13e-a3e57a0b8cfc",
                "6c815063-4c83-41e6-9450-38b1291922aa",
                "8827252d-cea7-4d61-841c-dbc997d6a857",
            ]
        },
        {
            "name": "Rroma Foundation",
            "id": "bdb00d06-60e8-4819-a4c6-d5b4e2ff5cf7",
            "target": "cat-collaborators",
            "width": 141,
            "urls": [
                "https://rroma.org/de/the-foundation/"
            ]
        },
        {
            "name": "Die grosse um_ordnung",
            "id": "c1a701dc-9c04-4633-b927-aaaa29c8b4ee",
            "target": "cat-collaborators",
            "width": 180,
            "urls": [
                "https://diegrosseumordnung.ch"
            ],
            relations: [
                "be17c9a6-d8c7-4fdd-920f-f4ef972d6ace",
                "f6cb0289-9c45-4aca-87e0-21d68d8dca81",
                "949c5c00-723a-4a57-884d-8f6eeb7a3a03",
                "c3b9b302-fe8f-4b58-85e5-6feedadf4932",
            ]
        },
        {
            "name": "Sabian Baumann",
            "id": "f33e9968-3186-4631-b69f-aad4ac5a4bee",
            "target": "cat-collaborators",
            "width": 131
        },
        {
            "name": "Alain Kupper",
            "id": "b1915240-d656-4022-bd97-23f729c15fc5",
            "target": "cat-collaborators",
            "width": 100,
            relations: [
                "0b97a7c8-5a2f-477b-854c-f112a61c5505"
            ]
        },
        {
            "name": "Suzana Milevska",
            "id": "81f38822-7bbe-447a-87c7-cd9f2aead47b",
            "target": "cat-collaborators",
            "width": 130,
            relations: [
                "adf89bad-959b-4728-8ebc-380ba16dbdbd"
            ]
        },
        {
            "name": "Shedhalle Zürich",
            "id": "f671181c-6816-44c6-a550-515ee3da3389",
            "target": "cat-collaborators",
            "width": 130,
            relations: [
                "57a00248-6393-4ebf-98e8-ffa97760886a",
                "be17c9a6-d8c7-4fdd-920f-f4ef972d6ace",
                "f6cb0289-9c45-4aca-87e0-21d68d8dca81",
                "949c5c00-723a-4a57-884d-8f6eeb7a3a03",
                "c3b9b302-fe8f-4b58-85e5-6feedadf4932",
            ]
        },
        {
            "name": "Beverly Carpenter",
            "id": "6c815063-4c83-41e6-9450-38b1291922aa",
            "target": "cat-collaborators",
            "width": 140,
            relations: [
                "85a460b9-e317-4868-9c5b-23198c81a4be",
            ]
        },
        {
            "name": "Olga Stefan",
            "id": "9b9e051a-1bec-4b1a-a71c-b2b37e731407",
            "target": "cat-collaborators",
            "width": 92,
            "urls": [
                "https://olgaistefan.wordpress.com/exhibitions-2013-now/guess-whos-coming-to-dinner-hospitality-as-artistic-practice/"
            ]
        },
        {
            "name": "INES – Institut neue Schweiz",
            "id": "13b7449f-2915-47a3-914b-2f52cf77c0c0",
            "target": "cat-collaborators",
            "width": 218,
            "urls": [
                "https://institutneueschweiz.ch"
            ],
            relations: [
                "766b1a6d-2e41-45eb-9a3b-12a364683988",
                "c28da457-b585-4d8b-80f1-3d1492cc445d",
                "6fedc4f4-2d9d-4b6f-831f-642f413b0c86",
            ]
        },
        {
            "name": "Lucy Powell",
            "id": "a265562d-f095-4cff-8164-255508f32e1b",
            "target": "cat-collaborators",
            "width": 91.1,
            "urls": [
                "http://www.tradukas.de/index.php?id=7&L=1"
            ],
            relations: [
                "c0eba797-d484-4a4f-af0c-c633a185c27d"
            ]
        },
        {
            "name": "Melanie Newton",
            "id": "c0eba797-d484-4a4f-af0c-c633a185c27d",
            "target": "cat-collaborators",
            "width": 123.4,
            "urls": [
                "http://www.tradukas.de/index.php?id=7&L=1"
            ],
            relations: [
                "a265562d-f095-4cff-8164-255508f32e1b"
            ]
        },
        {
            "name": "Rohit Jain",
            "id": "766b1a6d-2e41-45eb-9a3b-12a364683988",
            "target": "cat-collaborators",
            "width": 74.6,
            relations: [
                "13b7449f-2915-47a3-914b-2f52cf77c0c0"
            ]
        },
        {
            "name": "Kijan Espahangisi",
            "id": "c28da457-b585-4d8b-80f1-3d1492cc445d",
            "target": "cat-collaborators",
            "width": 133.5,
            relations: [
                "13b7449f-2915-47a3-914b-2f52cf77c0c0"
            ]
        },
        {
            "name": "Dominik Gross",
            "id": "6fedc4f4-2d9d-4b6f-831f-642f413b0c86",
            "target": "cat-collaborators",
            "width": 113.5,
            relations: [
                "13b7449f-2915-47a3-914b-2f52cf77c0c0"
            ]
        },
        {
            "name": "Performance credit für die 2nd Roma Biennale",
            "id": "e864107c-33c9-42e3-88fb-9937f680cf0e",
            "target": "cat-performances",
            "width": 353.5,
            "body": "Poster Aktion",
            "urls": [
                "https://phralipen.hr/en/2021/10/02/we-are-here-2nd-roma-biennale-explores-the-history-and-current-reality-of-romani-people/"
            ]
        },
        {
            "name": "Performance credit für die 1st Roma Biennale",
            "id": "237a4287-8d97-4b0f-ae1b-7a7ac6fda162",
            "target": "cat-performances",
            "width": 353.5,
            "urls": [
                "https://romatrial.org/projekte/roma-biennale/"
            ],
            relations: [
                "be1f4ff8-94b9-43cb-930c-9f899e353e04"
            ]
        },
        {
            "name": "Kunsthaus Zürich",
            "id": "bcc3fac6-6f38-4be6-ad62-83d8399cc5b8",
            "target": "cat-collaborators",
            "width": 133.5
        },
        {
            "name": "Europa. Die Zukunft der Geschichte 2015",
            "id": "758bcb4d-0421-4e2e-b1f1-af6c80b8ae7f",
            "target": "cat-performances",
            "width": 314,
            "urls": [
                "https://digilab.kunsthaus.ch/de/exhibition/europa-die-zukunft-der-geschichte"
            ]
        },
        {
            "name": "Catherine Hug",
            "id": "a7614c83-13c4-4c12-acd4-c235bdf6257a",
            "target": "cat-collaborators",
            "width": 112
        },
        {
            "name": "1st Roma Biennale, Berlin",
            "id": "be1f4ff8-94b9-43cb-930c-9f899e353e04",
            "target": "cat-collaborators",
            "width": 193.1,
            relations: [
                "237a4287-8d97-4b0f-ae1b-7a7ac6fda162"
            ]
        },
        {
            "name": "58. Biennale Di Venezia - Chroma the Future is Roma – we pass the Mic to Europe",
            "id": "6bf03a6b-c4d6-4ce5-ac8c-b5930a7e7faf",
            "target": "cat-performances",
            "width": 616.2,
            "urls": [
                "https://eriac.org/chroma-the-future-is-roma-we-pass-the-mic-to-europe/"
            ]
        },
        {
            "name": "Commissioned Artists for the exhibition 50 Anniversary World Roma Congress London & Berlin curated by Beverly Carpenter and Moritz Pankok",
            "id": "8827252d-cea7-4d61-841c-dbc997d6a857",
            "target": "cat-performances",
            "width": 1090.5,
            relations: [
                "85a460b9-e317-4868-9c5b-23198c81a4be"
            ]
        },
        {
            "name": "Performance credit für Shedhalle: in Lästiger Gesellschaft",
            "id": "0e11cf21-0fef-4962-937c-e41e107a8b06",
            "target": "cat-performances",
            "width": 439
        },
        {
            "name": "Hamze Bytyci",
            "target": "cat-collaborators",
            "body": "Roma  Activist",
            "id": "58c2a9b3-0473-4692-bca1-72d70ad821d6",
            width: 105.8,
            "urls": [
                "https://romatrial.org",
                "https://romatrial.org/kunst-und-kultur/come-out-now-1st-roma-biennale/",
                "https://romatrial.org/aktuell/die-2-roma-biennale-we-are-here-startet-mit-der-2-phase-widerstand-resilienz/"
            ],
            relations: [
                "d0e886f9-2abd-416d-b3e7-cee5d7871f3a"
            ]
        },
        {
            "name": "Reinhard Schmidt",
            "id": "bd2218b7-ff9b-4059-8640-ff2f3059cd7b",
            "target": "cat-collaborators",
            "width": 136.7,
            "urls": [
                "http://www.reinhard-schmidt.ch/"
            ]
        },
        {
            "name": "Jovanka Popova",
            "id": "879d8ce6-48a4-4bf2-bdfe-24311728ee4e",
            "target": "cat-collaborators",
            "width": 124.3,
            relations: [
                "be3b2479-bbcc-48eb-af22-cbb700697fcc"
            ]
        },
        {
            "name": "Mira Gacina",
            "id": "be7a3d15-c879-42e0-9565-73935546b040",
            "target": "cat-collaborators",
            width: 93,
            relations: [
                "be3b2479-bbcc-48eb-af22-cbb700697fcc"
            ]
        },
        {
            "name": "Angela Mattli",
            "target": "cat-collaborators",
            "id": "2d491ede-b5ad-455b-bb8e-67d4f8fd6bbe",
            width: 102.2,
            "urls": [
                "www.gfbv.ch"
            ],
            relations: [
                "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac",
                "bde0726f-f74a-4f7e-a32c-e1a1d428deba",
            ]
        },
        {
            "name": "GfbV: Fact Sheet - Anerkennung der Roma in der Schweiz",
            "target": "cat-articles",
            "id": "d50649e2-6ca7-49c3-962e-e44a6e9029f9",
            width: 437.7,
            "urls": [
                "https://www.sachdokumentation.ch/bestand/ds/1325"
            ],
            relations: [
                "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac",
            ]
        },
        {
            "name": "GfbV: Sinti und Roma: Wir sind wie ihr!",
            "target": "cat-articles",
            "id": "657bf782-6e86-40d1-b533-74899a9ffb51",
            width: 288,
            "urls": [
                "https://www.gfbv.de/de/zeitschriftfuervielfalt/archiv/301-sinti-und-roma-wir-sind-wie-ihr/"
            ],
            relations: [
                "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac",
            ]
        },
        {
            "name": "GfbV: Publikationen über «Roma-Sinti-Jenische»",
            "target": "cat-articles",
            "id": "5515fc9f-2fcc-44d0-8f11-bdc2c89a8ed0",
            width: 367.7,
            "urls": [
                "https://www.gfbv.ch/de/artikel/roma-sinti-jenische/"
            ],
            relations: [
                "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac",
            ]
        },
        {
            "name": "GfbV: Roma, Sinti und Jenische in der Schweiz",
            "target": "cat-articles",
            "id": "1bae4505-239f-4d79-99f6-35a5a218d892",
            width: 352,
            "urls": [
                "https://www.gfbv.ch/wp-content/uploads/a211268_gfbv_voice2_2020_de_web.pdf"
            ],
            relations: [
                "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac",
            ]
        },
        {
            "name": "GfbV: Ignoranz ist Gift – Anerkennung der Roma als Teil der Schweiz",
            "target": "cat-articles",
            "id": "b113be42-cc6a-47b6-8714-d3eba3fcfe5a",
            width: 515,
            "urls": [
                "https://www.gfbv.ch/de/medien/medienmitteilungen/ignoranz-ist-gift/"
            ],
            relations: [
                "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac",
            ]
        },
        {
            "name": "GfbV: Ignoranz ist Gift: Respekt und Anerkennung für Roma",
            "target": "cat-articles",
            "id": "b6c1a67c-2a74-4da0-9407-fa84a1878601",
            width: 449.5,
            "urls": [
                "https://www.gfbv.ch/wp-content/uploads/53871_voice_1_17_de.pdf"
            ],
            relations: [
                "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac",
            ]
        },
        {
            "name": "GfbV: Tag der Jenischen, Sinti und Roma in der Schweiz: Respekt und Anerkennung",
            "target": "cat-articles",
            "id": "d2fa5441-653b-4ef5-bdb0-5bc7ddd9515f",
            width: 628,
            "urls": [
                "https://www.gfbv.ch/de/medien/medienmitteilungen/tag-der-jenischen-sinti-und-roma/"
            ],
            relations: [
                "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac",
            ]
        },
        {
            "name": "GfbV: Keine Anerkennung als nationale Minderheit: Roma Organisationen kritisieren Bundesratsentscheid",
            "target": "cat-articles",
            "id": "223984ec-3350-44fa-a19d-2c85644c5742",
            width: 796,
            "urls": [
                "https://www.gfbv.ch/de/medien/medienmitteilungen/keine-anerkennung-roma/"
            ],
            relations: [
                "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac",
            ]
        },
        {
            "name": "Humanrichts: Keine Anerkennung für Roma als nationale Minderheit",
            "target": "cat-articles",
            "id": "031e6f89-f5c1-49e0-a877-a3719b906374",
            width: 514,
            "urls": [
                "https://www.humanrights.ch/de/ipf/menschenrechte/jenische-sinti-roma/roma-nationale-minderheit"
            ]
        },
        {
            "name": "Berner Zeitung",
            "target": "cat-articles",
            "id": "7a51a428-a719-4ef4-81b9-af57b7984544",
            width: 115,
            "urls": [
                "https://www.bernerzeitung.ch/ein-tag-mit-den-roma-440080450206"
            ]
        },
        {
            "name": "Swissinfo",
            "target": "cat-articles",
            "id": "06aa2940-4663-4427-a43f-0cf46f021435",
            width: 74.2,
            "urls": [
                "https://www.swissinfo.ch/ger/schweizer-roma-gelten-auch-kuenftig-nicht-als-nationale-minderheit/44161438"
            ]
        },
        {
            "name": "Katharina Morawek",
            "target": "cat-collaborators",
            "id": "57a00248-6393-4ebf-98e8-ffa97760886a",
            "width": 149,
            "body": "Shedhalle Zürich 2013-2016",
            "urls": [
                "http://archiv2017.shedhalle.ch/de/114/DIE_SCHWEIZ_IST_KEINE_INSEL"
            ],
            relations: [
                "f671181c-6816-44c6-a550-515ee3da3389",
            ]
        },
        {
            "name": "Swissinfo.ch: Die Schweiz errichtete ein Bollwerk gegen Roma",
            "target": "cat-articles",
            "id": "9e5efec8-0f3e-42d1-8c4e-b278f8cc0999",
            width: 470,
            "urls": [
                "https://www.swissinfo.ch/ger/kultur/die-schweiz-errichtete-ein-bollwerk-gegen-roma/48418610"
            ]
        },
        {
            "name": "GfbV: Roma, Sinti und Jenische in der Schweiz: Stopp Antiziganismus",
            "target": "cat-collaborators",
            "id": "8ba3e542-22a1-4b81-8064-b3eafcf84da4",
            width: 524,
            "urls": [
                "https://www.gfbv.ch/de/medien/medienmitteilungen/roma-sinti-und-jenische-in-der-schweiz-stopp-antiziganismus/"
            ],
            relations: [
                "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac",
            ]
        },
        {
            "name": "GfbV: Zum Begriff: Sinti und Roma",
            "target": "cat-collaborators",
            "id": "52d5d35b-a0ec-48ea-8400-eabb37cb99e4",
            width: 262,
            "urls": [
                "https://www.gfbv.de/de/informieren/themen/europa/voelker/roma/"
            ],
            relations: [
                "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac",
            ]
        },
        {
            "name": "Stiftung Zukunft für Schweizer Fahrende",
            "target": "cat-collaborators",
            "id": "9f26b9d5-70a7-48d1-a9a6-f39c981ffc92",
            width: 310,
            "urls": [
                "https://www.stiftung-fahrende.ch/"
            ],
            relations: [
                "3e38c0f0-5008-46b5-9352-e0bfaddeddc7",
            ]
        },
        {
            "name": "Stiftung Zukunft für Schweizer Fahrende",
            "target": "cat-articles",
            "id": "3e38c0f0-5008-46b5-9352-e0bfaddeddc7",
            width: 310,
            "urls": [
                "https://www.stiftung-fahrende.ch/it/archivio-articoli/roma-in-der-schweiz-n-geschichte-gegenwart-zukunft-604.html"
            ],
            relations: [
                "9f26b9d5-70a7-48d1-a9a6-f39c981ffc92"
            ]
        },
        {
            "name": "Humanrights.ch: #JenischeSintiRoma",
            "target": "cat-articles",
            "id": "3a542ae0-6bf0-4acf-b4b7-8c2d23987fb3",
            width: 286,
            "urls": [
                "https://www.humanrights.ch/de/ipf/menschenrechte/jenische-sinti-roma/"
            ]
        },
        {
            "name": "Kath.ch: «Roma stinken nicht!» – Aktionstag von Schweizer Roma",
            "target": "cat-articles",
            "id": "d3272cbd-6379-487f-a4dc-b1e480cfd5f6",
            width: 490.1,
            "urls": [
                "https://www.kath.ch/newsd/romas-stinken-nicht-aktionstag-von-schweizer-romas/"
            ]
        },
        {
            "name": "Sozialinfo.ch: Jenische, Sinti und Roma - Minderheiten in der Schweiz",
            "target": "cat-articles",
            "id": "a9840fd3-4607-463f-954d-d3b84ae83ba4",
            width: 524,
            "urls": [
                "https://www.sozialinfo.ch/fachinformationen/fokus/jenische-sinti-roma"
            ]
        },
        {
            "name": "Der Bund:«Die Anerkennung als Roma gäbe mir mehr Sicherheit»",
            "target": "cat-articles",
            "id": "9889014f-0a8b-427b-a0b2-22ebd0b38c09",
            width: 492,
            "urls": [
                "https://www.derbund.ch/die-anerkennung-als-roma-gaebe-mir-mehr-sicherheit-996435634508"
            ]
        },
        {
            "name": "Stiftung Fahrende: VOICE – Sonderausgabe zu Roma in der Schweiz",
            "target": "cat-articles",
            "id": "b0b3a65f-cd58-4022-9301-ff20670732f9",
            width: 517,
            "urls": [
                "https://www.stiftung-fahrende.ch/de/archiv-presseschau/voice-n-sonderausgabe-zu-roma-in-der-schweiz-592.html"
            ]
        },
        {
            "name": "GfBV: Jenische, Sinti und Roma sind in der Schweiz noch immer diskriminiert",
            "target": "cat-articles",
            "id": "73ffe81c-3169-4761-8638-b980b6420ae6",
            width: 582,
            "urls": [
                "https://www.gfbv.ch/de/medien/medienmitteilungen/neuer-bericht-der-gesellschaft-fuer-bedrohte-voelker-gfbv-zeigt-auf-jenische-sinti-und-roma-sind-in-der-schweiz-noch-immer-diskriminiert/"
            ],
            relations: [
                "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac",
            ]
        },
        {
            "name": "GfbV: Schattenbericht der Gesellschaft für bedrohte Völker zum vierten Bericht der Schweiz zur Umsetzung des Rahmenübereinkommens des Europarates zum Schutz nationaler Minderheiten",
            "target": "cat-articles",
            "id": "726ba14c-3a9e-4b8c-96c3-0cfbb448b683",
            width: 1452,
            "urls": [
                "https://www.gfbv.ch/wp-content/uploads/schattenbericht_zusammenfassung_d_def.pdf"
            ],
            relations: [
                "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac",
            ]
        },
        {
            "name": "GfbV: Mehr Platz für fahrende Roma",
            "target": "cat-articles",
            "id": "a8dac73a-e544-4e03-98b0-bb22d12ab9a9",
            width: 278,
            "urls": [
                "https://www.gfbv.ch/de/regionen/platz-fuer-fahrende-roma/"
            ],
            relations: [
                "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac",
            ]
        },
        {
            "name": "Bundesversammlung: Anerkennung der Roma im Rahmen des Rahmenübereinkommens zum Schutz nationaler Minderheiten des Europarates",
            "target": "cat-articles",
            "id": "29a124aa-f158-43f7-89ad-ccf3a0e44a8f",
            width: 1075,
            "urls": [
                "https://www.parlament.ch/de/ratsbetrieb/suche-curia-vista/geschaeft?AffairId=20173111"
            ],
            relations: [
                "c4260963-4d0d-4ed4-bf9f-4f24e108d5fe"
            ]
        },
        {
            "name": "Barbary Gysi",
            "target": "cat-collaborators",
            "id": "c4260963-4d0d-4ed4-bf9f-4f24e108d5fe",
            width: 99,
            "urls": [
                "https://www.parlament.ch/de/ratsbetrieb/suche-curia-vista/geschaeft?AffairId=20173111"
            ],
            relations: [
                "29a124aa-f158-43f7-89ad-ccf3a0e44a8f"
            ]
        },
        {
            "name": "GfBV: Jenische, Sinti und Roma in der Schweiz: Nationalrätinnen fordern eine nationale Task Force zur Umsetzung der rechtlichen Verpflichtungen zum Schutz der nationalen Minderheiten",
            "target": "cat-articles",
            "id": "dda75e1e-70c8-4a6c-82d0-7b78692269d6",
            width: 1410.4,
            "urls": [
                "https://www.gfbv.ch/de/medien/medienmitteilungen/jenische-sinti-und-roma-nationale-task-force/"
            ],
            relations: [
                "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac",
            ]
        },
        {
            "name": "GfBV: Halteplatz in Wileroltigen – wichtiger Schritt für fahrende Minderheiten",
            "target": "cat-articles",
            "id": "18c3a1a1-465f-4497-980f-dd1a5005c786",
            width: 583.2,
            "urls": [
                "https://www.gfbv.ch/de/medien/medienmitteilungen/wilteroltigen-bewilligt/"
            ],
            relations: [
                "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac",
            ]
        },
        {
            "name": "Researchgate.net: »Zigeunerpolitik« reloaded Racial Profiling von Jenischen, Sint*ezza und Rom*nja in der Schweiz",
            "target": "cat-articles",
            "id": "bde0726f-f74a-4f7e-a32c-e1a1d428deba",
            width: 868,
            "urls": [
                "https://www.researchgate.net/publication/338267487_Zigeunerpolitik_reloaded_Racial_Profiling_von_Jenischen_Sintezza_und_Romnja_in_der_Schweiz",
                "https://www.transcript-verlag.de/author/mattli-angela-320023565/"
            ],
            relations: [
                "2d491ede-b5ad-455b-bb8e-67d4f8fd6bbe",
            ]
        },
        {
            "name": "Die Schweiz ist keine Insel #4: Über die Grenzen",
            "target": "cat-performances",
            "id": "be17c9a6-d8c7-4fdd-920f-f4ef972d6ace",
            width: 363,
            "urls": [
                "http://archiv2017.shedhalle.ch/de/110/ÜBER_DIE_GRENZEN"
            ],
            relations: [
                "c1a701dc-9c04-4633-b927-aaaa29c8b4ee",
                "f671181c-6816-44c6-a550-515ee3da3389",
            ]
        },
        {
            "name": "Die Schweiz ist keine Insel #1: In lästiger Gesellschaft",
            "target": "cat-performances",
            "id": "f6cb0289-9c45-4aca-87e0-21d68d8dca81",
            width: 400.6,
            "urls": [
                "http://archiv2017.shedhalle.ch/de/113/IN_LÄSTIGER_GESELLSCHAFT"
            ],
            relations: [
                "c1a701dc-9c04-4633-b927-aaaa29c8b4ee",
                "f671181c-6816-44c6-a550-515ee3da3389",
            ]
        },
        {
            "name": "Einladung zum Inselversenken",
            "target": "cat-performances",
            "id": "949c5c00-723a-4a57-884d-8f6eeb7a3a03",
            width: 229.2,
            "urls": [
                "http://archiv2017.shedhalle.ch/de/415/EINLADUNG_ZUM_INSELVERSENKEN"
            ],
            relations: [
                "c1a701dc-9c04-4633-b927-aaaa29c8b4ee",
                "f671181c-6816-44c6-a550-515ee3da3389",
            ]
        },
        {
            "name": "Auftakt für ein Vermittlungsprogramm: Die Shedhalle ist keine insel",
            "target": "cat-articles",
            "id": "c3b9b302-fe8f-4b58-85e5-6feedadf4932",
            width: 506.1,
            "urls": [
                "http://archiv2017.shedhalle.ch/de/411/DIE_SHEDHALLE_IST_KEINE_INSEL"
            ],
            relations: [
                "c1a701dc-9c04-4633-b927-aaaa29c8b4ee",
                "f671181c-6816-44c6-a550-515ee3da3389",
            ]
        },
        {
            "name": "Chroma – The future is Roma – We pass the mic to Europe",
            "target": "cat-performances",
            "id": "07044578-5010-4c95-b0ba-8afd204012e5",
            width: 441,
            "urls": [
                "https://eriac.org/chroma-the-future-is-roma-we-pass-the-mic-to-europe/"
            ]
        },
        {
            "name": "Textwurf.ch",
            "target": "cat-collaborators",
            "id": "2bf3a262-99b2-478a-b118-60ea5bddae35",
            width: 88,
            "body": "Proofreading, editing, translation",
            "urls": [
                "www.textwurf.ch"
            ]
        },
        {
            "name": "Interview Gilda-Nancy Horvath – The front-page of the New York Times",
            "target": "cat-articles",
            "id": "0611b2c0-cda0-4f55-9277-fd778a0d564f",
            width: 535.9,
            "urls": [
                "https://www.romarchive.eu/de/collection/interview-gilda-nancy-horvath-the-front-page-of-the-new-york-times-2/"
            ],
            relations: [
                "46d15c32-4831-427f-bad2-293b8834a236",
                "3e92649c-c213-4d1f-bc33-ae68d0e5dbcc"
            ]
        },
        {
            "name": "Seien wir realistisch! Bild-Politik",
            "target": "cat-articles",
            "id": "deb75752-576a-445c-ba24-65785a078f73",
            width: 242.4,
            "urls": [
                "https://kunsthallewien.at/veranstaltung/seien-wir-realistisch-bild-politik/"
            ],
            relations: [
                "46d15c32-4831-427f-bad2-293b8834a236"
            ]
        },
        {
            "name": "#Webrom2014 – Roma Digital Identities",
            "target": "cat-articles",
            "id": "c4c993c4-5cf9-4ceb-bc10-3f17d032f95b",
            width: 305.7,
            "urls": [
                "http://www.romane-thana.at/kapitel-24.php"
            ],
            relations: [
                "46d15c32-4831-427f-bad2-293b8834a236"
            ]
        },
        {
            "name": "romatrial",
            "target": "cat-collaborators",
            "id": "46d15c32-4831-427f-bad2-293b8834a236",
            width: 69.5,
            "urls": [
                "https://romatrial.org/kunst-und-kultur/come-out-now-1st-roma-biennale/"
            ],
            relations: [
                "0611b2c0-cda0-4f55-9277-fd778a0d564f",
                "deb75752-576a-445c-ba24-65785a078f73",
                "c4c993c4-5cf9-4ceb-bc10-3f17d032f95b",
            ]
        },
        {
            "name": "Die 2. Roma-Biennale WE ARE HERE! startet mit der 2. Phase #Widerstand & Resilienz",
            "target": "cat-articles",
            "id": "13b4855b-eebe-48a7-855c-73e01b38d58a",
            width: 657,
            "urls": [
                "https://romatrial.org/aktuell/die-2-roma-biennale-we-are-here-startet-mit-der-2-phase-widerstand-resilienz/"
            ]
        },
        {
            "name": "Blokmagazine: COME OUT NOW! 1st Roma Biennale at Maxim Gorki Theater",
            "target": "cat-articles",
            "id": "f013eacf-9ed8-4b8c-a5aa-99e7e82af8fa",
            width: 582,
            "urls": [
                "https://blokmagazine.com/come-out-now-1st-roma-biennale/"
            ]
        },
        {
            "name": "Articles & Publications",
            "id": "cat-articles",
            "category": true,
            "width": 174
        },
        {
            "name": "Collaborators",
            "id": "cat-collaborators",
            "category": true,
            "width": 105.7
        },
        {
            "name": "Theory",
            "id": "cat-theory",
            "category": true,
            "width": 55.9
        },
        // {
        //     "name": "Videos",
        //     "id": "cat-videos",
        //     "category": true,
        //     "width": 54.9
        // },
        {
            "name": "Talks",
            "id": "cat-talks",
            "category": true,
            "width": 41.4
        },
        {
            "name": "Performances",
            "id": "cat-performances",
            "category": true,
            "width": 110.8
        }
    ],
    "links": [
        { source: "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac", target: "cat-collaborators" },
        { source: "2fda9539-a295-4fe0-b8e4-9e2c2bbf9656", target: "cat-collaborators" },
        { source: "19e68797-acb8-42a4-92d6-fa2118735eee", target: "cat-collaborators" },
        { source: "3e92649c-c213-4d1f-bc33-ae68d0e5dbcc", target: "cat-collaborators" },
        { source: "00264546-0b8a-435d-a999-dfa6e9e503cc", target: "cat-articles" },
        { source: "5625f2b2-3d6e-4ad0-8a68-614c652c3241", target: "cat-articles" },
        { source: "2c5f75ec-458d-4718-b4fb-736858c6b48d", target: "cat-performances" },
        { source: "90793963-25e7-40c2-ac52-9c7ca72fe23d", target: "cat-performances" },
        { source: "145c025d-2e4c-49e4-a08f-ffea5fcc98b9", target: "cat-talks" },
        { source: "79248642-7077-4440-b3c2-e83f015f2bc5", target: "cat-talks" },
        { source: "fbfdc394-1e11-4a22-97b8-607ae36894a3", target: "cat-talks" },
        { source: "97eb1bf0-8ed1-41a1-8e32-d5e5e34335bd", target: "cat-theory" },
        { source: "adf89bad-959b-4728-8ebc-380ba16dbdbd", target: "cat-theory" },
        { source: "83f46038-544d-4130-b746-1c1ebaa01198", target: "cat-talks" },
        { source: "0b97a7c8-5a2f-477b-854c-f112a61c5505", target: "cat-collaborators" },
        { source: "69eaea20-61ce-4525-b7e7-f8a655f6078d", target: "cat-collaborators" },
        { source: "a6d1d5dd-2183-411f-9707-921dd68ccd02", target: "cat-collaborators" },
        { source: "0faa7d73-61e9-41ec-a69f-ab8cdeed37f1", target: "cat-collaborators" },
        { source: "60ca306c-b050-4eed-bd3b-0a6f59b0e8e1", target: "cat-collaborators" },
        { source: "d0e886f9-2abd-416d-b3e7-cee5d7871f3a", target: "cat-collaborators" },
        { source: "be3b2479-bbcc-48eb-af22-cbb700697fcc", target: "cat-collaborators" },
        { source: "c237723c-1691-4084-9d14-8c7ab8bb3f8b", target: "cat-collaborators" },
        { source: "20670e69-3dbc-44e5-b13e-a3e57a0b8cfc", target: "cat-collaborators" },
        { source: "85a460b9-e317-4868-9c5b-23198c81a4be", target: "cat-collaborators" },
        { source: "bdb00d06-60e8-4819-a4c6-d5b4e2ff5cf7", target: "cat-collaborators" },
        { source: "c1a701dc-9c04-4633-b927-aaaa29c8b4ee", target: "cat-collaborators" },
        { source: "f33e9968-3186-4631-b69f-aad4ac5a4bee", target: "cat-collaborators" },
        { source: "b1915240-d656-4022-bd97-23f729c15fc5", target: "cat-collaborators" },
        { source: "81f38822-7bbe-447a-87c7-cd9f2aead47b", target: "cat-collaborators" },
        { source: "f671181c-6816-44c6-a550-515ee3da3389", target: "cat-collaborators" },
        { source: "9b9e051a-1bec-4b1a-a71c-b2b37e731407", target: "cat-collaborators" },
        { source: "6c815063-4c83-41e6-9450-38b1291922aa", target: "cat-collaborators" },
        { source: "13b7449f-2915-47a3-914b-2f52cf77c0c0", target: "cat-collaborators" },
        { source: "a265562d-f095-4cff-8164-255508f32e1b", target: "cat-collaborators" },
        { source: "c0eba797-d484-4a4f-af0c-c633a185c27d", target: "cat-collaborators" },
        { source: "766b1a6d-2e41-45eb-9a3b-12a364683988", target: "cat-collaborators" },
        { source: "c28da457-b585-4d8b-80f1-3d1492cc445d", target: "cat-collaborators" },
        { source: "6fedc4f4-2d9d-4b6f-831f-642f413b0c86", target: "cat-collaborators" },
        { source: "e864107c-33c9-42e3-88fb-9937f680cf0e", target: "cat-performances" },
        { source: "237a4287-8d97-4b0f-ae1b-7a7ac6fda162", target: "cat-performances" },
        { source: "bcc3fac6-6f38-4be6-ad62-83d8399cc5b8", target: "cat-collaborators" },
        { source: "758bcb4d-0421-4e2e-b1f1-af6c80b8ae7f", target: "cat-performances" },
        { source: "a7614c83-13c4-4c12-acd4-c235bdf6257a", target: "cat-collaborators" },
        { source: "be1f4ff8-94b9-43cb-930c-9f899e353e04", target: "cat-collaborators" },
        { source: "6bf03a6b-c4d6-4ce5-ac8c-b5930a7e7faf", target: "cat-performances" },
        { source: "8827252d-cea7-4d61-841c-dbc997d6a857", target: "cat-performances" },
        { source: "0e11cf21-0fef-4962-937c-e41e107a8b06", target: "cat-performances" },
        { source: "58c2a9b3-0473-4692-bca1-72d70ad821d6", target: "cat-collaborators" },
        { source: "bd2218b7-ff9b-4059-8640-ff2f3059cd7b", target: "cat-collaborators" },
        { source: "879d8ce6-48a4-4bf2-bdfe-24311728ee4e", target: "cat-collaborators" },
        { source: "be7a3d15-c879-42e0-9565-73935546b040", target: "cat-collaborators" },
        { target: "cat-collaborators", source: "2bf3a262-99b2-478a-b118-60ea5bddae35" },
        { target: "cat-collaborators", source: "2d491ede-b5ad-455b-bb8e-67d4f8fd6bbe" },
        { target: "cat-articles", source: "d50649e2-6ca7-49c3-962e-e44a6e9029f9" },
        { target: "cat-articles", source: "657bf782-6e86-40d1-b533-74899a9ffb51" },
        { target: "cat-articles", source: "5515fc9f-2fcc-44d0-8f11-bdc2c89a8ed0" },
        { target: "cat-articles", source: "1bae4505-239f-4d79-99f6-35a5a218d892" },
        { target: "cat-articles", source: "b113be42-cc6a-47b6-8714-d3eba3fcfe5a" },
        { target: "cat-articles", source: "b6c1a67c-2a74-4da0-9407-fa84a1878601" },
        { target: "cat-articles", source: "d2fa5441-653b-4ef5-bdb0-5bc7ddd9515f" },
        { target: "cat-articles", source: "223984ec-3350-44fa-a19d-2c85644c5742" },
        { target: "cat-articles", source: "031e6f89-f5c1-49e0-a877-a3719b906374" },
        { target: "cat-articles", source: "7a51a428-a719-4ef4-81b9-af57b7984544" },
        { target: "cat-articles", source: "06aa2940-4663-4427-a43f-0cf46f021435" },
        { target: "cat-collaborators", source: "57a00248-6393-4ebf-98e8-ffa97760886a" },
        { target: "cat-articles", source: "9e5efec8-0f3e-42d1-8c4e-b278f8cc0999" },
        { target: "cat-collaborators", source: "8ba3e542-22a1-4b81-8064-b3eafcf84da4" },
        { target: "cat-collaborators", source: "52d5d35b-a0ec-48ea-8400-eabb37cb99e4" },
        { target: "cat-collaborators", source: "9f26b9d5-70a7-48d1-a9a6-f39c981ffc92" },
        { target: "cat-articles", source: "3e38c0f0-5008-46b5-9352-e0bfaddeddc7" },
        { target: "cat-articles", source: "3a542ae0-6bf0-4acf-b4b7-8c2d23987fb3" },
        { target: "cat-articles", source: "d3272cbd-6379-487f-a4dc-b1e480cfd5f6" },
        { target: "cat-articles", source: "a9840fd3-4607-463f-954d-d3b84ae83ba4" },
        { target: "cat-articles", source: "9889014f-0a8b-427b-a0b2-22ebd0b38c09" },
        { target: "cat-articles", source: "b0b3a65f-cd58-4022-9301-ff20670732f9" },
        { target: "cat-articles", source: "73ffe81c-3169-4761-8638-b980b6420ae6" },
        { target: "cat-articles", source: "726ba14c-3a9e-4b8c-96c3-0cfbb448b683" },
        { target: "cat-articles", source: "a8dac73a-e544-4e03-98b0-bb22d12ab9a9" },
        { target: "cat-articles", source: "29a124aa-f158-43f7-89ad-ccf3a0e44a8f" },
        { target: "cat-collaborators", source: "c4260963-4d0d-4ed4-bf9f-4f24e108d5fe" },
        { target: "cat-articles", source: "dda75e1e-70c8-4a6c-82d0-7b78692269d6" },
        { target: "cat-articles", source: "18c3a1a1-465f-4497-980f-dd1a5005c786" },
        { target: "cat-articles", source: "bde0726f-f74a-4f7e-a32c-e1a1d428deba" },
        { target: "cat-performances", source: "be17c9a6-d8c7-4fdd-920f-f4ef972d6ace" },
        { target: "cat-performances", source: "f6cb0289-9c45-4aca-87e0-21d68d8dca81" },
        { target: "cat-performances", source: "949c5c00-723a-4a57-884d-8f6eeb7a3a03" },
        { target: "cat-articles", source: "c3b9b302-fe8f-4b58-85e5-6feedadf4932" },
        { target: "cat-performances", source: "07044578-5010-4c95-b0ba-8afd204012e5" },
        { target: "cat-articles", source: "0611b2c0-cda0-4f55-9277-fd778a0d564f" },

                // { target: "cat-collaborators", body: "Proofreading, editing, translation" },
        // { target: "cat-collaborators", body: "ERIAC Board Member " },
                // { target: "cat-collaborators", body: "Roma  Activist" },
        { target: "cat-articles", source: "deb75752-576a-445c-ba24-65785a078f73" },
        { target: "cat-articles", source: "c4c993c4-5cf9-4ceb-bc10-3f17d032f95b" },

        { target: "cat-collaborators", source: "46d15c32-4831-427f-bad2-293b8834a236" },
        { target: "cat-articles", source: "13b4855b-eebe-48a7-855c-73e01b38d58a" },
        { target: "cat-articles", source: "f013eacf-9ed8-4b8c-a5aa-99e7e82af8fa" },
        { target: "cat-collaborators", source: "83263e81-3a3e-4b61-80d3-43dcb91983b3" },
        {
            target: "cat-articles",
            source: "32b3e1e8-ad97-4b7c-8195-d1acb685e8ba",
        },
        { target: "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac", source: "2d491ede-b5ad-455b-bb8e-67d4f8fd6bbe", special: true },
        { target: "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac", source: "5625f2b2-3d6e-4ad0-8a68-614c652c3241", special: true },
        { target: "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac", source: "fbfdc394-1e11-4a22-97b8-607ae36894a3", special: true },
        { target: "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac", source: "83f46038-544d-4130-b746-1c1ebaa01198", special: true },
        { target: "3e92649c-c213-4d1f-bc33-ae68d0e5dbcc", source: "83f46038-544d-4130-b746-1c1ebaa01198", special: true },
        { target: "3e92649c-c213-4d1f-bc33-ae68d0e5dbcc", source: "60ca306c-b050-4eed-bd3b-0a6f59b0e8e1", special: true },
        { target: "3e92649c-c213-4d1f-bc33-ae68d0e5dbcc", source: "0faa7d73-61e9-41ec-a69f-ab8cdeed37f1", special: true },

        { target: "85a460b9-e317-4868-9c5b-23198c81a4be", source: "20670e69-3dbc-44e5-b13e-a3e57a0b8cfc", special: true },
        { target: "b1915240-d656-4022-bd97-23f729c15fc5", source: "0b97a7c8-5a2f-477b-854c-f112a61c5505", special: true },
        { target: "81f38822-7bbe-447a-87c7-cd9f2aead47b", source: "adf89bad-959b-4728-8ebc-380ba16dbdbd", special: true },
        { target: "57a00248-6393-4ebf-98e8-ffa97760886a", source: "f671181c-6816-44c6-a550-515ee3da3389", special: true },
        { target: "6c815063-4c83-41e6-9450-38b1291922aa", source: "85a460b9-e317-4868-9c5b-23198c81a4be", special: true },
        { target: "6c815063-4c83-41e6-9450-38b1291922aa", source: "85a460b9-e317-4868-9c5b-23198c81a4be", special: true },
        { target: "c0eba797-d484-4a4f-af0c-c633a185c27d", source: "a265562d-f095-4cff-8164-255508f32e1b", special: true },

        { target: "13b7449f-2915-47a3-914b-2f52cf77c0c0", source: "766b1a6d-2e41-45eb-9a3b-12a364683988", special: true },
        { target: "13b7449f-2915-47a3-914b-2f52cf77c0c0", source: "c28da457-b585-4d8b-80f1-3d1492cc445d", special: true },
        { target: "13b7449f-2915-47a3-914b-2f52cf77c0c0", source: "6fedc4f4-2d9d-4b6f-831f-642f413b0c86", special: true },

        { target: "237a4287-8d97-4b0f-ae1b-7a7ac6fda162", source: "be1f4ff8-94b9-43cb-930c-9f899e353e04", special: true },
        { target: "8827252d-cea7-4d61-841c-dbc997d6a857", source: "85a460b9-e317-4868-9c5b-23198c81a4be", special: true },
        { target: "58c2a9b3-0473-4692-bca1-72d70ad821d6", source: "d0e886f9-2abd-416d-b3e7-cee5d7871f3a", special: true },
        { target: "879d8ce6-48a4-4bf2-bdfe-24311728ee4e", source: "be3b2479-bbcc-48eb-af22-cbb700697fcc", special: true },

        { target: "be7a3d15-c879-42e0-9565-73935546b040", source: "be3b2479-bbcc-48eb-af22-cbb700697fcc", special: true },

        { target: "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac", source: "2d491ede-b5ad-455b-bb8e-67d4f8fd6bbe", special: true },
        { target: "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac", source: "d50649e2-6ca7-49c3-962e-e44a6e9029f9", special: true },
        { target: "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac", source: "657bf782-6e86-40d1-b533-74899a9ffb51", special: true },
        { target: "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac", source: "5515fc9f-2fcc-44d0-8f11-bdc2c89a8ed0", special: true },
        { target: "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac", source: "1bae4505-239f-4d79-99f6-35a5a218d892", special: true },
        { target: "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac", source: "b113be42-cc6a-47b6-8714-d3eba3fcfe5a", special: true },
        { target: "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac", source: "b6c1a67c-2a74-4da0-9407-fa84a1878601", special: true },
        { target: "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac", source: "d2fa5441-653b-4ef5-bdb0-5bc7ddd9515f", special: true },
        { target: "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac", source: "223984ec-3350-44fa-a19d-2c85644c5742", special: true },
        { target: "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac", source: "8ba3e542-22a1-4b81-8064-b3eafcf84da4", special: true },
        { target: "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac", source: "52d5d35b-a0ec-48ea-8400-eabb37cb99e4", special: true },
        { target: "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac", source: "73ffe81c-3169-4761-8638-b980b6420ae6", special: true },
        { target: "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac", source: "726ba14c-3a9e-4b8c-96c3-0cfbb448b683", special: true },
        { target: "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac", source: "a8dac73a-e544-4e03-98b0-bb22d12ab9a9", special: true },
        { target: "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac", source: "18c3a1a1-465f-4497-980f-dd1a5005c786", special: true },
        { target: "340eb5b2-0dd6-452c-a4f1-9a4fc603f0ac", source: "dda75e1e-70c8-4a6c-82d0-7b78692269d6", special: true },

        { target: "3e38c0f0-5008-46b5-9352-e0bfaddeddc7", source: "9f26b9d5-70a7-48d1-a9a6-f39c981ffc92", special: true },

        { target: "29a124aa-f158-43f7-89ad-ccf3a0e44a8f", source: "c4260963-4d0d-4ed4-bf9f-4f24e108d5fe", special: true },

        { target: "bde0726f-f74a-4f7e-a32c-e1a1d428deba", source: "2d491ede-b5ad-455b-bb8e-67d4f8fd6bbe", special: true },

        { target: "be17c9a6-d8c7-4fdd-920f-f4ef972d6ace", source: "c1a701dc-9c04-4633-b927-aaaa29c8b4ee", special: true },
        { target: "be17c9a6-d8c7-4fdd-920f-f4ef972d6ace", source: "f671181c-6816-44c6-a550-515ee3da3389", special: true },

        { target: "f6cb0289-9c45-4aca-87e0-21d68d8dca81", source: "c1a701dc-9c04-4633-b927-aaaa29c8b4ee", special: true },
        { target: "f6cb0289-9c45-4aca-87e0-21d68d8dca81", source: "f671181c-6816-44c6-a550-515ee3da3389", special: true },

        { target: "949c5c00-723a-4a57-884d-8f6eeb7a3a03", source: "c1a701dc-9c04-4633-b927-aaaa29c8b4ee", special: true },
        { target: "949c5c00-723a-4a57-884d-8f6eeb7a3a03", source: "f671181c-6816-44c6-a550-515ee3da3389", special: true },

        { target: "c3b9b302-fe8f-4b58-85e5-6feedadf4932", source: "c1a701dc-9c04-4633-b927-aaaa29c8b4ee", special: true },
        { target: "c3b9b302-fe8f-4b58-85e5-6feedadf4932", source: "f671181c-6816-44c6-a550-515ee3da3389", special: true },


        { target: "0611b2c0-cda0-4f55-9277-fd778a0d564f", source: "46d15c32-4831-427f-bad2-293b8834a236", special: true },

        { target: "0611b2c0-cda0-4f55-9277-fd778a0d564f", source: "3e92649c-c213-4d1f-bc33-ae68d0e5dbcc", special: true },

        { target: "deb75752-576a-445c-ba24-65785a078f73", source: "46d15c32-4831-427f-bad2-293b8834a236", special: true },
        { target: "c4c993c4-5cf9-4ceb-bc10-3f17d032f95b", source: "46d15c32-4831-427f-bad2-293b8834a236", special: true },
    ]
}
